// app/javascript/application.js
import "bootstrap";  // BootstrapをCDNからインポート
import "my_calendar";
import "calendar";
import "jquery"
// Rails UJSを有効化
import "@rails/ujs";


// フィールド追加・削除のイベント
document.addEventListener("click", function (event) {
  const target = event.target;

  // フィールド追加
  if (target.matches("[data-add-fields]")) {
    event.preventDefault();
    const time = new Date().getTime();
    const regexp = new RegExp(target.dataset.id, "g");
    document.querySelector(target.dataset.container).insertAdjacentHTML(
      "beforeend",
      target.dataset.fields.replace(regexp, time)
    );
  }

  // フィールド削除
  if (target.matches("[data-remove-fields]")) {
    event.preventDefault();
    const field = target.closest(".fields");
    if (field) field.remove();
  }
});
