document.addEventListener("DOMContentLoaded", function () {
  const calendarEl = document.getElementById("my_calendar");
  if (!calendarEl) {
    console.error("カレンダー要素が見つかりません");
    return;
  }

  try {
    const plugins = [
      window.FullCalendar.DayGrid ? window.FullCalendar.DayGrid.default : null,  // DayGridプラグインを確認
      window.FullCalendar.Interaction ? window.FullCalendar.Interaction.default : null  // Interactionプラグインを確認
    ].filter(plugin => plugin !== null);  // nullを除外

    const calendar = new window.FullCalendar.Calendar(calendarEl, {
      plugins: plugins, // 動的に追加されたプラグイン
      locale: "ja",
      headerToolbar: {
        left: "prev",
        center: "title",
        right: "next",
      },
      titleFormat: { year: "numeric", month: "long" },
      buttonText: {
        today: "今日",
        month: "月",
        week: "週",
        day: "日",
      },
      initialView: "dayGridMonth",
      events: "/reports.json", // イベントの取得先
      dateClick: function (info) {
        console.log("クリックした日付:", info.dateStr);

        // 日付の取得
        const clickedDate = new Date(info.date);

        // タイムゾーンを補正して日付のフォーマットを調整
        const localeDate = clickedDate.toLocaleDateString("ja-JP", {
          timeZone: "Asia/Tokyo"
        }); // 日本時間に変換
        const formattedDate = localeDate.replace(/\//g, "-"); // "YYYY-MM-DD"形式に

        const events = calendar.getEvents().filter(event => {
          const eventDate = new Date(event.start);
          return clickedDate.toDateString() === eventDate.toDateString();
        });

        if (events.length > 0) {
          // イベントがあれば、そのイベント詳細ページに遷移
          const event = events[0];
          console.log(`イベントが見つかりました。遷移先: /reports/${event.id}`);
          if (window.Turbolinks) {
            Turbolinks.visit(`/reports/${event.id}`);
          } else {
            window.location.href = `/reports/${event.id}`;
          }
        } else {
          // イベントがなければ新規作成画面に遷移
          console.log(`イベントがありません。新規作成画面へ遷移: /reports/new?start=${formattedDate}`);
          if (window.Turbolinks) {
            Turbolinks.visit(`/reports/new?start=${formattedDate}`);
          } else {
            window.location.href = `/reports/new?start=${formattedDate}`;
          }
        }
      },
      eventContent: function (eventInfo) {
        const title = eventInfo.event.title || "";
        const span = document.createElement("span");
        span.innerHTML = title;
        return { domNodes: [span] };
      },
      height: "auto",
      contentHeight: "auto",
    });

    calendar.render();
    console.log("カレンダーの初期化が完了しました");
  } catch (error) {
    console.error("カレンダーの初期化中にエラーが発生しました:", error);
  }
});
