document.addEventListener('DOMContentLoaded', () => {
  const calendarEl = document.getElementById('calendar');

  if (calendarEl) {
    const calendar = new window.FullCalendar.Calendar(calendarEl, {
      headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'next',
      },
      selectable: true,
      select: (info) => {
        const start = info.startStr;
        window.location.href = `/events/new?start=${start}`;
      },
      titleFormat: { year: 'numeric', month: 'long' },
      timeFormat: 'H:mm',
      locale: 'ja',
      buttonText: {
        today: '今日',
        month: '月',
        week: '週',
        day: '日',
      },
      events: '/events.json',
    });

    calendar.render();
  }
});
